import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 80px);
  padding: 4rem 0;
  width: 100%;
  display: table;
`;

export const ErrorContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 108rem;
  margin: 0 auto;
  position: relative;
  text-align: center
`;

export const ErrorTitle = styled.h1`
  font-weight: 600;
  font-size: 10rem;
  color: $black;
  line-height: 1
`;

export const ErrorText = styled.h2`
  font-weight: 600;
  font-size: 3.7rem;
  color: $black;
  line-height: (41/37);
`;

export const ErrorSupport = styled.p`
  font-size: 1.7rem;
  color: $black;
  line-height: (31/17);

  @media all and (max-width: 60em) {
    .error-message__support {
      display:none
    }
  }
`;

export const NavigationLinks = styled.div`
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const NavigationLink = styled.a`
  display: flex !important;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 80px;

  font-size: 14px;
  color: #fff;
  letter-spacing: 0;
  line-height: 0;
  text-decoration: none;
  vertical-align: middle;

  transition: opacity .2s ease-in-out;

  @media only screen and (min-width: 361px) {
    .navigation-overlay__link {
        width: 50%;
    }
  }

  @media only screen and (min-width: 601px) {
    .navigation-overlay__link {
        width: 25%;
    }
  }
`;

export const NavigationIcon = styled.span`
  background: #333;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavigationText = styled.span`
  color: #333;
  font-size: 17px;
  margin-top: 30px;
`;