import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { I18nextProvider } from 'react-i18next'; // as we build ourself via webpack
import { ApolloProvider } from 'react-apollo';
import Cookies from 'universal-cookie';
import { ENV, BUILD_ENV } from '@lushdigital/helper-functions';
import { AuthProvider, getTokensClient } from '@lushdigital/auth';
import * as Sentry from '@sentry/browser';

import 'isomorphic-fetch';

import createi18nInstance from './i18n'; // initialised i18next instances

// Load SCSS
import '../../scss/app.scss';
import { sentryKey } from '../config/sentry';

// Defined by webpack.
const version = webpackVars.VERSION;

Sentry.init({
  dsn: sentryKey,
  environment: BUILD_ENV,
  release: version,
  blacklistUrls: ['localhost']
});

// Ensure latest version.
if (localStorage) {
  if (version !== localStorage.getItem('version')) {
    localStorage.clear();
    try {
      localStorage.setItem('version', version);
    } catch (e) {
      console && console.log('could not set version into localStorage');
    }
  }
}

function buildI18nStore(appName) {
  if (window.__i18n) {
    return {
      [window.__i18n.locale]: {
        [appName]: window.__i18n.resources[appName],
        common: window.__i18n.resources.common,
        alerts: window.__i18n.resources.alerts
      }
    };
  }
  return null;
}

const renderApp = async ({ appName, Client, apollo }) => {
  // Force no render for npm start of multi app structure
  let RENDER = true;
  if (ENV === 'development') {
    RENDER = window.location.pathname.replace(/^\/+/g, '').split('/')[1] === appName; // offset to account for language param
  }
  if (!RENDER && appName !== 'dashboard') return null;

  // Create i18n instance
  const i18n = await createi18nInstance(appName);
  const locale = window.__i18n ? window.__i18n.locale : 'en'; // eslint-disable-line

  const cookies = new Cookies();
  const tokens = getTokensClient(cookies);

  Sentry.configureScope((scope) => {
    scope.setTag('page_locale', locale);
    scope.setTag('app_name', appName);
  });

  return ReactDOM.hydrate(
    <ApolloProvider client={ apollo }>
      <AuthProvider tokens={ tokens }>
        <AppContainer warnings={ false }>
          <I18nextProvider
            i18n={ i18n }
            initialI18nStore={ buildI18nStore(appName) }
            initialLanguage={ locale }
          >
            <Client />
          </I18nextProvider>
        </AppContainer>
      </AuthProvider>
    </ApolloProvider>,
    document.getElementById('root')
  );
};

const buildClient = ({ appName, Client, apollo }) => {
  return renderApp({
    appName,
    Client,
    apollo
  });
};

export default buildClient;