import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector';

import CONFIG from '../config';

const createi18nInstance = (namespace) => {
  return new Promise((resolve, reject) => {
    const instance = i18n
      .use(XHR)
      .use(Cache)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: 'en',
        wait: true, // globally set to wait for loaded translations in withTranslation hoc

        ns: [namespace, 'common'],
        defaultNS: namespace,
        fallbackNS: 'common',

        detection: {
          order: [
            'htmlTag',
            'querystring',
            'cookie',
            'localStorage',
            'navigator'
          ]
        },

        debug: false,

        cache: {
          enabled: true
        },

        load: 'all',

        lngWhitelist: CONFIG.languages,

        lowerCaseLng: true,

        interpolation: {
          escapeValue: false,
          defaultVariables: {
            blank: ''
          }
        },

        backend: {
          loadPath: '/client/locales/{{lng}}.json?{{ns}}'
        }
      });

    instance.then(() => {
      resolve(i18n);
    });
    instance.catch((error) => {
      console.error('Error loading i18next', error);
      reject(error);
    });
  });
};

export default createi18nInstance;
