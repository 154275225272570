import { get, has } from 'lodash';
import uuid from 'uuid/v4';
import history from '../config/browserHistory';
import serverHistory from '../config/serverHistory';
import { getToken } from './token';

export const isBrowser = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export function ssrSafeRedirect(url) {
  if (isBrowser) {
    return history.push(url);
  }
  return serverHistory.push(url);
}

export function generateUUID() {
  return uuid();
}

export function strToURL(url) {
  return url
    .toLowerCase()
    .trim()
    .replace(/\s|[-]+/g, '-')
    .replace(/\/|\.|<|>|‘|’|\\|"|,|\?|'|=|&/g, ''); // replace known troublesome characters
}

export const translateAlerts = alert => {
  let message = { ...alert };

  if (has(alert, 'translation_string') && isBrowser) {
    const ts = get(alert, 'translation_string');

    const locale = get(window, '__i18n.locale', 'en');
    const translations = get(
      window,
      `__i18n.${ locale }`,
      get(window, '__i18n.resources')
    );

    message = {
      ...alert,
      message: get(translations, `alerts.${ ts }`, alert.message)
    };
  }

  return message;
};

// Strips emoji from strings
export const stripEmoji = string => {
  const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  return string ? string.replace(emojiRegex, '') : '';
};

export const isFreeOfEmoji = (string = '') => {
  return stripEmoji(string).length === string.length;
};

export const downloadFile = async (url, fileName) => {
  if (!url) throw new Error('url cannot be empty or undefined');
  if (!fileName) throw new Error('fileName cannot be empty or undefined');

  const token = await getToken();
  return fetch(url, { headers: { Authorization: `Bearer ${ token }` } })
    .then(response => response.blob())
    .then(blob => {
      // Create blob link to download
      const fileUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    });
};