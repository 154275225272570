import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import Header, { NavigationProvider } from '@lushdigital/header';
import Icon from '@lushdigital/icons';
import { Translation, withTranslation } from 'react-i18next';
import { parse } from 'query-string';
import { AlertProvider, AlertConsumer } from '@salocreative/alerts';
import { AuthWrapper, withAuth } from '@lushdigital/auth';

import renderRoutes from '../../../../components/app/renderRoutes';
import routesConfig from '../index';
import AppConfig from '../../config';
import ROLES from '../../../../config/groups.json';
import { ssrSafeRedirect } from '../../../../helpers';

class App extends Component {
  componentDidUpdate(prevProps) {
    const { location, jwt } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
    // Handle redirect if tills referrer for login
    const { return: returnURL, language } = parse(location.search);
    if (returnURL === 'tills' && !isEmpty(jwt) && jwt !== prevProps.jwt) {
      ssrSafeRedirect(`/${ language || 'en' }/tills`);
    }
  }

  render() {
    const { location } = this.props;
    return (
      <div className='App'>
        <Helmet titleTemplate='%s | Manager' />
        <NavigationProvider>
          <AuthWrapper>
            <Header
              disableAppNav={ true }
              location={ location }
              title='Manager'
              roles={ ROLES }
            />
          </AuthWrapper>

          <div className='page'>
            <AlertProvider>
              <AlertConsumer
                closeIcon={ <Icon icon='close' fill='#fff' size='10px' /> }
                topOffset={ 80 }
              />
              <Translation ns={ ['common'] }>
                { (t, { i18n }) => renderRoutes(this.props, routesConfig, i18n.language) }
              </Translation>
            </AlertProvider>
          </div>
        </NavigationProvider>
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired,
  jwt: PropTypes.object.isRequired
};

export default withTranslation([AppConfig.name])(withAuth(App));