import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Router, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

// HELPERS
import history from '../config/browserHistory';

export default class Client extends Component {
  render() {
    const { AppContainer } = this.props;
    return (
      <Router history={ history }>
        <LastLocationProvider>
          <Suspense fallback={ () => <p>Loading…</p> }>
            <Route component={ AppContainer } />
          </Suspense>
        </LastLocationProvider>
      </Router>
    );
  }
}

Client.propTypes = {
  AppContainer: PropTypes.any.isRequired
};