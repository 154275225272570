import { union } from 'lodash';
import ROLES from '../../../config/groups.json';

const base = '/:language';

export const DASHBOARD = {
  path: `${ base }`,
  title: '404_ERROR',
  permissions: union(ROLES.CREATOR.ALL, ROLES.PEOPLE.ALL, ROLES.TILLS.ALL, ROLES.STOCK.ALL)
};

export const FOUROHFOUR = {
  path: `${ base }/*`,
  title: '404_ERROR',
  permissions: union(ROLES.CREATOR.ALL, ROLES.PEOPLE.ALL, ROLES.TILLS.ALL, ROLES.STOCK.ALL)
};