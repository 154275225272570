import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Icon from '@lushdigital/icons';
import { withNavTitle } from '@lushdigital/header';
import { withTranslation } from 'react-i18next';
import { AuthWrapper } from '@lushdigital/auth';

import {
  ErrorWrapper,
  ErrorContainer,
  ErrorTitle,
  ErrorText,
  ErrorSupport,
  NavigationLinks,
  NavigationLink,
  NavigationIcon,
  NavigationText
} from './styles';

// HELPERS
import { appList } from '../../helpers';

class NotFound extends Component {
  renderLinks() {
    const {
      match: {
        params: { language }
      }
    } = this.props;
    return appList.map((val) => {
      return (
        <AuthWrapper permissions={val.permissions} key={val.name}>
          <NavigationLink
            href={val.href
              .replace(':language', language)
              .replace(
                'https://manager-develop.frontendserviceaccount.com/',
                '/'
              )}
          >
            <NavigationIcon>
              <Icon icon={val.icon} size='35px' fill='#fff' />
            </NavigationIcon>
            <NavigationText>{val.label}</NavigationText>
          </NavigationLink>
        </AuthWrapper>
      );
    });
  }
  render() {
    const { t } = this.props;
    return (
      <ErrorWrapper>
        <Helmet>
          <title>{t('404_ERROR')}</title>
        </Helmet>

        <ErrorContainer>
          <ErrorTitle>{t('404_ERROR')}</ErrorTitle>
          <ErrorText>{t('404_ERROR_TEXT')}</ErrorText>
          <ErrorSupport>{t('404_ERROR_SUPPORT')}</ErrorSupport>
          <NavigationLinks>{this.renderLinks()}</NavigationLinks>
        </ErrorContainer>
      </ErrorWrapper>
    );
  }
}

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

export default withTranslation(['common'])(withNavTitle(NotFound));
