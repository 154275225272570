import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { AuthRoute } from '@lushdigital/auth';

// Route Component
export default function renderRoutes(props, routesConfig, language = 'en') {
  return (
    <Switch>
      { routesConfig.map(route => {
        if (route.renderRedirect) {
          return (
            <Redirect
              exact={ route.exact }
              key={ route.path }
              path={ route.path }
              to={ route.redirect ? route.redirect.replace(':language', language) : '/:language/404' }
            />
          );
        }

        return (
          <AuthRoute
            language={ language }
            exact={ route.exact }
            key={ route.path }
            path={ route.path }
            componentProps={ props }
            title={ route.title }
            component={ route.component }
            authenticated={ route.authenticated }
            notAuthenticated={ route.notAuthenticated }
            permissions={ route.permissions ? route.permissions : [] }
            redirect={ route.redirect ? route.redirect : '/en/404' }
          />
        );
      }) }
    </Switch>
  );
}