import { appEnvironmentRoutes, ENV } from '@lushdigital/helper-functions';

import ROLES from '../../../config/groups.json';

const applicationRoutes = appEnvironmentRoutes(ENV);

export const appList = [
  {
    name: 'PEOPLE',
    href: applicationRoutes.PEOPLE,
    label: 'People',
    icon: 'profile',
    permissions: ROLES.PEOPLE.ALL
  },
  {
    name: 'TILLS',
    href: applicationRoutes.SHOP_MANAGER,
    label: 'Store Manager',
    icon: 'smartphone-landscape',
    permissions: ROLES.TILLS.ALL
  }, {
    name: 'STOCK',
    href: applicationRoutes.STOCK,
    label: 'Stock',
    icon: 'chart',
    permissions: ROLES.STOCK.ALL
  }, {
    name: 'TAG_MANAGER',
    href: applicationRoutes.TAG_MANAGER,
    label: 'Tag Manager',
    icon: 'tag',
    permissions: ROLES.CORE.ADMIN
  }, {
    name: 'CONNECT_FEED',
    href: applicationRoutes.CONNECT_FEED,
    label: 'Connect Feed',
    icon: 'connect',
    permissions: ROLES.CORE.SOCIAL_ADMIN
  }
];