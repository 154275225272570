module.exports = {
  // defaults
  WEEKDAY_DATE: 'ddd, MMM Do',
  DEFAULT: 'YYYY MM DD',
  LONG: 'ddd Do MMM YYYY',
  SHORT: 'DD MMM',
  SHORT_ORDINAL: 'Do MMM',
  MEDIUM: 'DD MMM YYYY',
  DATE_STRING: 'L',
  DATE_STRING_LONG: 'll', // shortened month name
  LONG_MONTH: 'MMMM YYYY',
  DISCUSSIONS_DATE: 'ddd Do MMM',
  DISCUSSIONS_TIME: 'HH:mm',

  // language specific
  'cs': {},
  'hu': {},
  'en': {},
  'es': {},
  'fr': {},
  'nl': {},
  'pt-br': {},
  'de': {},
  'it': {},
  'ja': {
    WEEKDAY_DATE: 'YYYY年MMMDo (dd)',
    DEFAULT: 'LLL',
    LONG: 'YYYY年MMMDo (dd)',
    SHORT: 'MMMDo',
    SHORT_ORDINAL: 'MMMDo',
    MEDIUM: 'DD MMM YYYY',
    DATE_STRING: 'L',
    DATE_STRING_LONG: 'll', // shortened month name
    LONG_MONTH: 'YYYY年MMM',
    DISCUSSIONS_DATE: 'MMMDo (dd)'
  },
  'ko': {},
  'ru': {},
  'sv': {},
  'zh-hk': {}

};