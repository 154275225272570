import { ENV } from '@lushdigital/helper-functions';

export const GraphQLUrl = (local = false) => {
  if (local && ENV === 'development') {
    return 'http://localhost:7000/graphql';
  }
  return webpackVars.GRAPHQL_URL;
};

export const getApolloBaseUrl = (local = false) => {
  if (local && ENV === 'development') {
    return 'http://localhost:7000';
  }
  return webpackVars.APOLLO_BASE_URL;
};