import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';

import { getToken } from '../../helpers/token';

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const JWT = await getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: JWT ? `Bearer ${ JWT }` : ''
    }
  };
});

// SETUP APOLLO CLIENT
const apollo = (GraphQLUrl) => new ApolloClient({
  name: 'manager',
  version: webpackVars.VERSION,
  link: authLink.concat(ApolloLink.from([
    createUploadLink({
      uri: GraphQLUrl,
      batchInterval: 50,
      fetch
    }),
    new BatchHttpLink({
      uri: GraphQLUrl,
      batchInterval: 50,
      fetch
    })
  ])),
  cache: new InMemoryCache().restore(window.__LUSH_COM_APOLLO_STATE)
});

export default apollo;