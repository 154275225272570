import Cookies from 'universal-cookie';
import { hasIn, get, find } from 'lodash';
import { getGatewayUrl, getEnvRoot, BUILD_ENV } from '@lushdigital/helper-functions';
import { tokenExpired, tokenFullyExpired } from '@lushdigital/auth';

const cookies = new Cookies();
const gateway = getGatewayUrl(BUILD_ENV);
const domain = getEnvRoot(BUILD_ENV);
const refreshTokenUri = `${ gateway }/token/refresh`;

export async function refreshToken({ jwt, returnObject }) {
  const response = await fetch(refreshTokenUri, { headers: { authorization: `Bearer ${ jwt.t }` } });
  const data = await response.json();
  if (data && data.code === 200) {
    if (hasIn(data, 'data.tokens')) {
      const tokenObj = find(data.data.tokens, { type: 'jwt' });
      const token = get(tokenObj, 'value', '');
      const newCookie = { ...jwt, t: token, ts: Date.now() };

      // If we aren't handling the object ourselves
      // return the token and set a new cookie
      if (!returnObject) {
        cookies.set('jwt', JSON.stringify(newCookie), { path: '/', domain });
        return token;
      }
      return newCookie;
    }
  }
  return '';
}

export async function getToken() {
  const jwt = cookies.get('jwt');
  if (jwt && jwt.t && jwt.ts) {
    // If we have a jwt cookie with a token (t) and timestamp (ts).
    if (!tokenExpired(jwt.ts)) {
      return jwt.t;
    }

    if (!tokenFullyExpired(jwt.ts)) {
      return refreshToken({ jwt });
    }
  }
  // No cookie found or malformed cookie.
  return '';
}