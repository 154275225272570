import { createBrowserHistory } from 'history';

const isBrowser = !!(
  (typeof window !== 'undefined' &&
    window.document && window.document.createElement)
);

let history = null;
if (isBrowser) {
  history = createBrowserHistory();
}

export default history;