import { appEnvironmentRoutes, ENV } from '@lushdigital/helper-functions';

import NotFound from '../pages/notFound';

import { FOUROHFOUR, DASHBOARD } from '../config/pages';

const routesConfig = [
  {
    exact: true,
    component: NotFound,
    authenticated: true,
    redirect: appEnvironmentRoutes(ENV).CORE,
    ...DASHBOARD
  },
  {
    exact: true,
    component: NotFound,
    authenticated: true,
    redirect: appEnvironmentRoutes(ENV).CORE,
    ...FOUROHFOUR
  }
];

export default routesConfig;