import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Breakpoint, Button, FluidType } from '@lushdigital/ui';
import Icon from '@lushdigital/icons';

export const loadSwipe = keyframes`
  from {
    width: 0
  },
  to {
    width: 100%
  }
`;

const Wrapper = styled.div`
  color: #fff;
  width: 100%;
  padding: 16px;
  min-height: 50px;

  a {
    color: currentColor;
  }

  ${ ({ type }) => {
    switch (type) {
      case 'error':
        return 'background: #D0021B;';
      case 'warning':
        return 'background: #F6A623;';
      case 'info':
        return 'background: #8F8F8F;';
      default:
        return 'background: #00A44C;';
    }
  } }

  ${ Breakpoint('small')`
    padding: 20px 30px;
    min-height: 70px;
  ` }

  ${ FluidType({ fontSize: [14, 17], lineHeight: [17, 21] }) }

  line-height: 1.125;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after {
    display: block;
    content: "";
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    height: 0.25em;
    background: rgba(255, 255, 255, 0.3);
    animation-fill-mode: both;
    animation: ${ loadSwipe } ${ ({ time }) => (time || 5) }s linear infinite;
  }
`;

const isDismissible = (alert) => {
  return typeof alert.dismissible === 'undefined' || alert.dismissible;
};

const Alert = ({ alert, clearAlert, setAlertClear }) => {
  setAlertClear(alert.id, alert.time);

  return (
    <Wrapper type={ alert.type } time={ alert.time }>
      <div dangerouslySetInnerHTML={ { __html: alert.message } } />
      { isDismissible(alert) && (
        <Button
          padding='0.5em'
          theme='inverse-floating'
          onClick={ () => clearAlert(alert.id) }
        >
          <Icon icon='close' />
        </Button>
      ) }
    </Wrapper>
  );
};

export default Alert;

Alert.propTypes = {
  alert: PropTypes.object.isRequired,
  clearAlert: PropTypes.func.isRequired,
  setAlertClear: PropTypes.func.isRequired
};